<template>
    <div :class="(error ? 'mfw-has-error ' : '') + 'mfw-step-select-col'">
        <div class="mfw-step-label">{{ question.text }}</div>
        <div class="mfw-input-wrap">
            <input
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                :placeholder="question.description"
                type="text" class="mfw-input">
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'question', 'error'],
        watch: {
            error: function(errorValue){
                if(!errorValue){
                    this.$emit('update:error')
                }
            }
        },
        methods: {
            itemClick(event) {
                var answer = Array.prototype.indexOf.call(event.target.parentNode.children, event.target) + 1;
                this.$emit('input', answer);
            }
        }
    }
</script>

