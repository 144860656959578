import Config from '../../../config/variables'

function urlPreffixByLocale(locale) {
  let preffix = '';
  if (locale !== 'de') {
    preffix = `/${locale}`
  }
  return preffix;
}

export default {
  methods: {
    base: function (localized = true) {
      return Config.domain + urlPreffixByLocale(this.$i18n.locale)
    }
  }
}