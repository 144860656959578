<template>
     <div :class="(error ? 'mfw-has-error ' : '') + 'mfw-popup-column'">
        <div class="mfw-popup-label">{{ question.text }}:</div>
        <div class="mfw-select-wrap">
            <select v-model="employeeId" v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
                <option value="">Please select</option>
                <option v-for="option in question.metaOptions" :value="option.id" :key="option.id">
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'question', 'error'],
        methods: {
            itemClick(event) {
                var answer = Array.prototype.indexOf.call(event.target.parentNode.children, event.target) + 1;
                this.$emit('input', answer);
            }
        }
    }
</script>

