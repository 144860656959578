<template>
    <div class="mfw-review-header-col">
        <div class="mfw-review-header-img" v-if="companyInfo.poster">
            <img :src="companyInfo.poster" :alt="companyInfo.name">
        </div>
        <div class="mfw-review-header-info">
            <div class="mfw-review-header-subtitle">{{ $t("feedback.Surgery_Title") }}</div>
            <div class="mfw-review-header-title">{{ companyInfo.name }}</div>
            <div v-if="companyInfo.address" class="mfw-review-header-text">
                <span>{{ companyInfo.address.street }},</span>
                <span>{{ companyInfo.address.zip }}</span>
                <span>{{ companyInfo.address.city }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['companyInfo']
    }
</script>
