<template>
    <div class="mfw-review-header-col">
        <div class="mfw-review-header-img" v-if="medicoInfo.poster152x152">
            <img :src="medicoInfo.poster152x152" :alt="medicoInfo.academicTitle + ' ' + medicoInfo.fullName">
        </div>
        <div class="mfw-review-header-info">
            <div class="mfw-review-header-subtitle">{{ medicoInfo.academicTitle }}</div>
            <div class="mfw-review-header-title">
                <div>{{ medicoInfo.firstName }} {{ medicoInfo.lastName }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['medicoInfo']
    }
</script>
