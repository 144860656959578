<template>
    <div class="mfw-common-row">
      <div class="mfw-row">{{ $t("header.Average") }}:
          <div class="mfw-value">{{ averageRounded + '%' }}</div>
      </div>
      <div class="mfw-row">{{ $t("header.Total_feedbacks") }}:
          <div class="mfw-value">{{ total }}</div>
      </div>
        <Rating :percentage="averageRounded"/>
    </div>
</template>

<script>
  import Rating from './Rating'

  export default {
    name: "AverageFeedbacksRating",
    props: ['average', 'total'],
    components: {
      Rating
    },
    data() {
      return {
        averageRounded: this.average.toFixed(0)
      };
    }
  }
</script>

