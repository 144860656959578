<template>
    <div class="mfw-review-completed-wrap mfw-review-completed-physio">
        <div class="mfw-review-completed-img"><img :src="require('@/assets/images/feedback-popup.png')" alt=""></div>
        <div class="mfw-review-completed-title">{{ $t('form.completed.title') }}</div>
        <div class="mfw-review-completed-content">
            <div class="mfw-review-completed-text">
                <p>Als <strong>Dankeschön</strong> schenken wir <strong>Ihnen 20% Rabatt</strong> auf eine <strong>Massage im Physiozentrum</strong>.</p>
            </div>
            <div class="mfw-promo-wrap">
                <img :src="require('@/assets/images/mfw-physiozentrum-logo.svg')" alt="">
                <p>Buchen Sie gleich jetzt Ihren Wunschtermin online und geben Sie folgenden Code ein:</p>
                <span class="mfw-promo">Danke20</span>
                <p class="mfw-promo-smaller">Voucher-Code bitte bei der Buchung ins entsprechende Feld eintragen.</p>
            </div>
            <div class="mfw-review-completed-text mfw-review-completed-text-b">
                <p><strong>JETZT MASSAGETERMIN BUCHEN:</strong></p>
                <ul>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-biel-bahnhofplatz-13-2502-biel/surgery/38202877?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Biel</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-bern-bahnhofplatz-10b-3011-bern/surgery/39144335?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Bern Bahnhof</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-lenzburg-bahnhofstrasse-38-5600-lenzburg/surgery/39919664?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Lenzburg</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-solothurn-dornacherstrasse-28-4500-solothurn/surgery/38451001?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Solothurn</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-basel-claraplatz-untere-rebgasse-8-4058-basel/surgery/41684847?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Basel Claraplatz</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-wil-obere-bahnhofstrasse-26-9500-wil/surgery/35840787?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Wil</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-winterthur-bahnhofplatz-8-8400-winterthur/surgery/35957338?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Winterthur</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-aarau-bahnhofplatz-3h-5000-aarau/surgery/39144300?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Aarau</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/physiozentrum-zurich-oerlikon-wallisellenstrasse-6-8050-zurich/surgery/36219730?utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget">Zürich Oerlikon</a>
                    </li>

                </ul>
                <div class="mfw-note">Der Rabatt-Code ist 24 Stunden ab erhalt gültig. Pro Person kann er nur einmal, nur für einen Massage-Termin und nur über die Online-Buchung eingelöst werden. Er ist nicht mit anderen Rabatten kumulierbar.</div>
            </div>


            <a href="https://www.medicosearch.ch/search/praxen?q=physiozentrum&withBookings=true&utm_source=medicosearch&utm_medium=ad&utm_campaign=physiozentrum_massage&utm_content=feedback_widget" class="mfw-button mfw-button-fill mfw-review-completed-button">
                Termin buchen
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubmittedStepSwissSmile",
    props: ['backURL']
}
</script>

<style scoped>

</style>
