<template>
    <div :class="(error ? 'mfw-has-error ' : '') + 'mfw-step-select-col'">
        <div class="mfw-step-label">{{ question.text }}</div>
        <div class="mfw-textarea-wrap">
            <textarea
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                :placeholder="question.description"
                rows="3" class="mfw-textarea">
            </textarea>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'question', 'error'],
        watch: {
            error: function(errorValue){
                if(!errorValue){
                    this.$emit('update:error')
                }
            }
        }
    }
</script>
