export default {
    data: () => ({
        error: false
    }),
    methods: {
        /**
         * @param {Promise} fetchPromise
         * @param callback
         * @param callbackError
         */
        handleError (error, data, callbackError, runDefaultErrorHandler) {
            if (callbackError) {
                callbackError(error, data)
                return
            }

            if (!callbackError || runDefaultErrorHandler) {
                if (process.env.NODE_ENV === 'development') {
                    this.$data.error = (typeof error === 'object' && 'message' in error) ? error.message : error
                } else {
                    this.$data.error = this.$t('form.Error_500.Text')
                }
            }
        },
        processResponse (fetchPromise, callback, callbackError, runDefaultErrorHandler = false) {
            return fetchPromise.then((data) => {
                if (('errors' in data) && data.errors.length !== 0) {
                    let error = false

                    if (typeof data.errors[0] === 'string') {
                        error = data.errors.join('. ')
                    } else if ('message' in data.errors[0]) {
                        error = data.errors.map((el) => {
                            return el.message
                        }).join('. ')
                    } else {
                        error = 'Unknown error'
                    }

                    if (error) {
                        this.handleError.call(this, error, data, callbackError, runDefaultErrorHandler)
                    }
                } else if (!data.success) {
                    this.handleError.call(this, 'Unknown error', data, callbackError, runDefaultErrorHandler)
                } else {
                    try {
                        callback(data)
                    } catch (error) {
                        this.handleError.call(this, error, data, callbackError, runDefaultErrorHandler)
                    }
                }
            }).catch((error) => {
                this.handleError.call(this, error, { code: 'HTTP_ERROR' }, callbackError, runDefaultErrorHandler)
            })
        }
    }
}
