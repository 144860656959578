<template>
    <div class="mfw-review-step" :class="[isFilled ? 'mfw-filled-in' : '', isCollapsing ? 'mfw-collapsing' : '']"
         v-bind:style="{height: height }">
        <Spinner v-if="isCollapsing" :size="50"/>
        <div class="mfw-step-number">1</div>
        <div class="mfw-step-title" ref="title">{{ $t('form.General_information') }}</div>
        <div v-if="isExpanded || !isFilled" ref="content" class="mfw-step-content">
            <div class="mfw-step-select-list">
                <div class="mfw-step-label">{{ $t('form.Reason_for_your_visit') }}</div>
                <div class="mfw-input-wrap">
                    <input type="text" class="mfw-input" v-model="reasonForVisit" />
                </div>
            </div>

            <div v-if="error" class="mfw-widget-error">
                {{ error }}
            </div>

            <div class="mfw-button-wrap" :class="(allowContinue ? '': 'mfw-error')">
                <a href="javascript:void(0);" class="mfw-button mfw-button-fill mfw-button-next"
                   v-on:click="next()">{{ $t('form.Next') }}</a>
            </div>
        </div>

        <div class="mfw-step-content" ref="content" v-else-if="isFilled">
            <div class="mfw-step-select-list">
                <div class="mfw-step-label">{{ $t('form.Reason_for_your_visit') }}:</div>
                <div class="mfw-step-answer">{{ reasonForVisit }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonMixin from '../../utils/mixins/Common'
    import Spinner from './common/Spinner'

    export default {
        components: {Spinner},
        name: "GeneralInfo",
        props: ['isExpanded', 'isFilled', 'reason'],
        mixins: [CommonMixin],
        data() {
            return {
                height: 'auto',
                maxHeight: null,
                isCollapsing: false,
                OPACITY_CONTENT_DURATION: 100,
                SPINNER_DURATION: 300,
                COLLAPSE_DURATION: 600,
                setTimeoutPromise: (ms) => new Promise(resolve => setTimeout(resolve, ms)),
                reasonForVisit: this.reason,
                error: false,
                allowContinue: false
            };
        },

        created() {
            this._toggleAllowContinue();
        },

        watch: {
            isFilled: function (newValue) {
                let self = this;

                if (newValue) return;

                self.isCollapsing = true;
                self.height = self.maxHeight + 'px';

                self.setTimeoutPromise(self.COLLAPSE_DURATION)
                    .then(() => {
                        self.isCollapsing = false;
                        self.height = 'auto';
                    })
            },
            reasonForVisit() {
                this._toggleAllowContinue();
            }
        },

        methods: {
            _toggleAllowContinue() {
                this.allowContinue = false;

                if (this.reasonForVisit && this.reasonForVisit.trim()) {
                    this.allowContinue = true;
                }
            },
            next() {
                let self = this;
                let parentPaddingTop = parseInt(window.getComputedStyle(this.$el, null).getPropertyValue('padding-top'));
                let parentPaddingBottom = parseInt(window.getComputedStyle(this.$el, null).getPropertyValue('padding-bottom'));

                self.isCollapsing = true;
                self.height = self.$refs.title.offsetHeight + self.$refs.content.clientHeight + parentPaddingTop + parentPaddingBottom + 'px';
                self.maxHeight = self.$el.offsetHeight;


                self.setTimeoutPromise(self.OPACITY_CONTENT_DURATION)
                    .then(() => {
                        self.$emit('changeView', 'next', self.reasonForVisit);
                        self.setTimeoutPromise(self.SPINNER_DURATION)
                            .then(() => {
                                self.isCollapsing = false;
                            });
                        self.setTimeoutPromise(0)
                            .then(() => {
                                self.height = self.$refs.title.offsetHeight + self.$refs.content.clientHeight + parentPaddingTop + parentPaddingBottom + 'px';
                                self.setTimeoutPromise(self.COLLAPSE_DURATION)
                                    .then(() => {
                                        self.height = 'auto';
                                    })
                            })
                    });
            },

        }
    }
</script>

<style scoped>

</style>
