export default {
  header: {
    Average: "Average",
    Total_feedbacks: "Feedbacks"
  },
  bottom: {
    All_feedbacks: "All feedbacks"
  },
  form: {
    Value_is_mandatory: "Value is mandatory",
    Employee: "Healthcare provider",
    Company: "Surgery",
    VideoConsultation: "MedicoVideo",
    Please_select: "Please select",
    Back: "Back",
    Next: "Next",
    Submit: "Send",
    Add_feedback: "Rate now",
    General_information: "General information",
    Reason_for_your_visit: "Reason for your visit",
    Please_leave_a_review_for: "Please leave a review for",
    Authentication_required: "Quality and fairness are very important for Medicosearch. Therefore, we can publish your review only if you register or log in.",
    completed: {
      title: "Review completed!",
      text: "Thank you for submitting your review. Your assessment will contribute to greater transparency in the healthcare system and will provide future patients with an important decision-making assistance in their search for a suitable doctor."
    },
    Error_404: {
      Title: "Page not found!",
      Text: "Sorry, we could not find the page you were looking for."
    },
    Error_500: {
      Title: "Something went wrong",
      Text: "Please try again later, or contact <a href=\"mailto:support@medicosearch.ch\">Medicosearch support</a>"
    }

  },
  feedback: {
    Surgery_Title: 'SURGERY',
    Verified_Patient: "Verified Patient",
    Last_Feedback: "Last Feedback",
    Rating_Description: "These ratings reflect the subjective feelings of the users. With a small number of ratings, it is possible that the overall rating is not representative.",
    Completed_Button: "Okay"
  }
}
