<template>
    <div>
        <template v-if="isExpanded && !isFilled">
            <div v-for="(category, categoryId) in categoriesMapLoc" :key="categoryId" class="mfw-step-list">
                <div class="mfw-step-label" v-if="category.showHeadline">{{ category.name }}</div>
                <div class="mfw-step-select-list">
                    <template v-for="(question, index) in category.questions" v-bind:value="question.id">
                        <component
                            v-if="!question.dependsOnQuestionId || questionsMap[question.dependsOnQuestionId].value"
                            :key="index"
                            v-model="question.value"

                            :error="question.error"
                            :question="question"
                            v-on:input="question.error = false"
                            v-on:update:error="setCommonErrorState"
                            v-bind:is="getComponentName(question)"/>
                    </template>
                </div>
            </div>

            <div v-on:click="validate" :class="(hasError ? 'mfw-error': '')">
                <slot name="bottom"></slot>
            </div>
        </template>
        <template class="mfw-step-content" v-else>
            <div class="mfw-step-select-list">
                <div class="mfw-step-answer" v-for="answer in textAnswers">{{answer}}</div>
                <RatingStars :percentage="rating"/>
            </div>
        </template>
    </div>
</template>

<script>
    import * as formComponents from '../formComponents/';
    import RatingStars from '../../Rating';
    import {scrollToY} from "../../../utils/Scroller";

    export default {
        components: Object.assign(formComponents, {RatingStars}),
        name: "QuestionList",
        props: ['isExpanded', 'isFilled', 'categoriesMap', 'questions', 'offsetY'],
        data() {
            const categoriesMapLoc = Object.assign({}, this.categoriesMap);

            const questionsMap = this.questions.reduce(function (map, obj) {
                map[obj.id] = obj;
                obj.error = false;
                return map;
            }, {});

            return {
                COLLAPSE_DURATION: 400,
                questionTypeToComponentNameMap: {
                    rating: formComponents.Rating,
                    textarea: formComponents.Textarea,
                    textline: formComponents.Input,
                    boolean: formComponents.YesNo,
                    option: formComponents.Option
                },
                rating: 0,
                textAnswers: '',
                questionsMap,
                scrollToError: false,
                hasError: false,
                categoriesMapLoc
            };
        },
        updated: function () {
            if (!this.scrollToError) {
                return;
            }

            this.scrollToError = !this.scrollToError;

            const elem = document.querySelector(".mfw-has-error");

            if (elem) {
                let scrollPosition = this.$parent.$el.offsetTop + document.getElementsByClassName("mfw-has-error")[0].parentNode.parentElement.offsetTop + this.offsetY();

                scrollToY(scrollPosition, this.COLLAPSE_DURATION, 'linear');
            }
        },
        methods: {
            setCommonErrorState: function () {
                this.hasError = false
            },

            getComponentName(question) {
                if (question.meta.type in this.questionTypeToComponentNameMap) {
                    let val = this.questionTypeToComponentNameMap[question.meta.type];
                    return this.questionTypeToComponentNameMap[question.meta.type];
                } else {
                    console.error(`${question.meta.type} is not known question type`);
                }
            },
            validate(event) {
                if (!event.target.classList.contains('mfw-d-submit-questions')) {
                    return;
                }

                this.hasError = false;
                for (const question of this.questions) {
                    question.error = false;
                    if (question.mandatory && !(question.value && question.value.trim())) {
                        question.error = this.$i18n.t('form.Value_is_mandatory');
                        this.hasError = true;
                    } else if (question.dependsOnQuestionId && !this.questionsMap[question.dependsOnQuestionId].value) {
                        question.value = null;
                    }
                }

                this.$emit('validate');
                if (!this.hasError) {
                    this.setShortInfo();

                    this.$emit('Submit');
                }
                this.scrollToError = true;

                this.categoriesMapLoc = Object.assign({}, this.categoriesMapLoc);
            },
            setShortInfo() {
                let ratingTotal = 0, answered = 0, textContent = [];
                for (let question of this.questions) {
                    if (!question.value) {
                        continue;
                    }

                    if (question.meta.type == 'rating' || question.meta.type == 'boolean' || question.meta.type == 'option') {
                        let option = question.metaOptions.find((el) => (el.id == question.value));
                        if (option) {
                            ratingTotal += option.rating;
                            answered++;
                        }
                    } else if (question.meta.type == 'textline') {
                        textContent.push(question.value);
                    }
                }

                this.rating = (ratingTotal / answered) / 5 * 100;
                this.textAnswers = textContent;
            }
        }
    }
</script>
