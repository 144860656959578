<template>
    <div class="mfw-list" ref="carouselWrap">
      <Slick ref="carousel" @init="onSliderInit" :options="slickOptions">
        <div class="mfw-item" v-for="feedback in feedbacks" :key="feedback.id">
          <div class="mfw-bg">
            <div class="mfw-top">
              <Rating :percentage="feedback.roundedRating"/>
              <div class="mfw-date">{{ feedback.dateCreated }}</div>
            </div>
            <div class="mfw-author">{{ feedback.feedbackTitle }}</div>
            <div class="mfw-txt">{{ feedback.feedbackDescription }}</div>
            <div class="mfw-bottom">{{ $t('feedback.Verified_Patient')}}</div>
          </div>
        </div>
      </Slick>
    </div>

</template>

<script>
  import Rating from './Rating'
  import Slick from 'vue-slick';
  import $ from 'jquery';

  export default {
    name: "FeedbacksList",
    props: ['feedbacks'],
    components: {
      Rating,
      Slick
    },
    data() {
      return {
        slickOptions: {
          slidesToShow: 3,
          slidesToScroll: 1,
          respondTo: 'slider',
          responsive: [
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2
              }
            },
              {
                  breakpoint: 500,
                  settings: {
                      slidesToShow: 1
                  }
              }
          ]
        }
      }
    },
    beforeUpdate() {
      if (this.$refs.carousel) {
        this.$refs.carousel.destroy();
      }
    },
    updated() {
      this.$nextTick(function () {
        if (this.$refs.carousel) {
          this.$refs.carousel.create(this.slickOptions);
        }
      });
    },
    methods: {
      resize() {
        $(this.$refs.carousel.$el).slick('resize');
      },
      onSliderInit() {
        setTimeout(() => {
          this.resize()
        }, 100);
      }
    }
  }
</script>
