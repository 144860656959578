<template>
  <div id="app">
    <div v-if="loading">
    </div>
    <div v-else>
      <template v-if="!error">
        <div class="mfw-one-feedback-wrap">
          <div class="mfw-common">
            <div class="mfw-logo"></div>
            <AverageFeedbacksRating v-bind:average="average" v-bind:total="totalFeedbacks"/>
          </div>
          <div class="mfw-last-feedback" v-if="feedback">
             <div class="mfw-last-feedback-title"> {{ $t('feedback.Last_Feedback') }}:</div>
            <div class="mfw-author">{{ feedback.feedbackTitle }}</div>
            <div class="mfw-txt">{{ feedback.feedbackDescription }}</div>
            <div class="mfw-bottom">
                {{ $t('feedback.Verified_Patient') }}
              <div class="mfw-date">
                {{ feedback.dateCreated }}
              </div>
            </div>
          </div>
          <div class="mfw-btn-wrap mfw-btn-with-logo">
            <a :href="allFeedbacksLink" class="mfw-link" id="popupFinal">{{ $t("bottom.All_feedbacks") }} ({{ totalFeedbacks }})</a>
          </div>
        </div>
      </template>
      <span v-else>{{ error }}</span>
    </div>
  </div>
</template>

<style scoped>
  [v-cloak] {
    display: none;
  }
</style>


<script>
  import Fetcher from './utils/Fetcher'
  import CommonMixin from './utils/mixins/Common'
  import AverageFeedbacksRating from "./components/AverageFeedbacksRating";
  import LinkGenerator from "./utils/mixins/LinkGenerator";
  import AppInitial from "./utils/mixins/AppInitial";

  require('./polyfills/Array.find.js');

  export default {
    name: 'OneFeedbackApp',
    props: ['displayFeedbacksCount', 'id', 'type'],
    components: {
      AverageFeedbacksRating
    },
    data: () => ({
      average: null,
      totalFeedbacks: null,
      error: false,
      loading: true,
      feedback: {},
      showModal: false,
      allFeedbacksLink: '#'
    }),
    mixins: [
      CommonMixin,
      LinkGenerator,
      AppInitial
    ],
    async created() {
      await this.initialLoad();
      this.reload();
    },
    methods: {
      reload() {
        this.summary = Fetcher.GetReceiveJson(`feedbacks/${this.profileId}/summary`);

        this.feedbacks = Fetcher.GetReceiveJson(`feedbacks/${this.profileId}?limit=1`);

        this.processResponse(this.feedbacks, (data) => {
          this.$data.feedback = data.object.items[0];
        });
      },
    },
    async beforeMount() {
      if (this.relationPromise) {
        await this.relationPromise;
      }
      await setImmediate(async () => {
        this.processResponse(this.summary, (data) => {
          this.$data.average = data.object.summary.average;
          this.$data.totalFeedbacks = data.object.summary.total;
          this.$data.loading = false;
        }, (error) => {
          this.$data.loading = false;
          this.$data.error = 'message' in error ? error.message : error;
        });

        await this.summary;
      })

    }
  }
</script>
