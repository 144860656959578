import en from './en'
import fr from './fr'
import it from './it'
import de from './de'

export default {
  en: en,
  de: de,
  fr: fr,
  it: it,
  
};
