<template>
    <div class="mfw-review-completed-wrap">
        <div class="mfw-review-completed-img"><img :src="require('@/assets/images/feedback-popup.png')" alt=""></div>
        <div class="mfw-review-completed-title">{{ $t('form.completed.title') }}</div>
        <div class="mfw-review-completed-content">
            <div class="mfw-review-completed-text">
                <p>Als <strong>Dankeschön</strong> möchten wir Ihnen einen <strong>Voucher für eine Zahnreinigung</strong> bei einer <strong>swiss smile Praxis in Zürich</strong> schenken.</p>


            </div>
            <div class="mfw-promo-wrap">
                <img :src="require('@/assets/images/mfw-swiss-logo.png')" alt="">
                <p>Geniessen Sie <strong>40 CHF Rabatt*</strong> für Ihre <br />nächste <strong>Zahnreinigung</strong>. Ihr Voucher-Code lautet:</p>
                <span class="mfw-promo">Medicosearch2021</span>
                <p class="mfw-promo-smaller">Voucher-Code bitte bei der Buchung ins entsprechende Feld eintragen.</p>
            </div>
            <div class="mfw-review-completed-text mfw-review-completed-text-b">
                <p><strong>JETZT ZAHNREINIGUNG BUCHEN:</strong></p>
                <ul>
                    <li>
                        <a href="https://www.medicosearch.ch/swiss-smile-bahnhofstrasse-110-bahnhofstrasse-110-8001-zurich/surgery/12384921?utm_source=medicosearch&utm_medium=ad&utm_campaign=swiss_smile_zurich&utm_content=feedback_widget">swiss smile Bahnhofsstrasse Zürich</a>
                    </li>
                    <li>
                        <a href="https://www.medicosearch.ch/swiss-smile-im-hauptbahnhof-shopville-hauptbahnhof-shopville-8001-zurich/surgery/176214?utm_source=medicosearch&utm_medium=ad&utm_campaign=swiss_smile_zurich&utm_content=feedback_widget">swiss smile Hauptbahnhof/ShopVille Zürich</a>
                    </li>
                </ul>
                <div class="mfw-note">* Einlösbar bis Ende Oktober 2021, nur für Neupatienten und nicht kombinierbar mit anderen Rabatten oder Aktionen.</div>
            </div>


            <a href="https://www.medicosearch.ch/search/praxen?q=swiss+smile&withBookings=true&utm_source=medicosearch&utm_medium=ad&utm_campaign=swiss_smile_zurich&utm_content=feedback_widget" class="mfw-button mfw-button-fill mfw-review-completed-button">
                Termin buchen
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubmittedStepSwissSmile",
    props: ['backURL']
}
</script>

<style scoped>

</style>
