import Config from '../../config/variables'

var apiEndpoint = Config.apiEndpoint;

export default class Fetcher {
    static globals = {};

    static setLang(lang) {
        Fetcher.globals.lang = lang;
    }

    static setApiKey(apiKey) {
        Fetcher.globals.apiKey = apiKey;
    }

    static buildUrl(apiEndpoint, version, relativeUrl) {
        let url = `${apiEndpoint}/${version}/${relativeUrl}`;

        if ('lang' in Fetcher.globals) {
            if (url.indexOf('?') !== -1) {
                url += `&lang=${Fetcher.globals.lang}`;
            } else {
                url += `?lang=${Fetcher.globals.lang}`;
            }
        }

        return url;
    }

    static GetReceiveJson(relativeUrl, version = 'v1', cache = false) {
        const headers = {};
        if ('apiKey' in Fetcher.globals) {
            headers['X-Api-Key'] = Fetcher.globals.apiKey
        }

        let fetchParams = {
            method: 'GET',
            cache: (cache ? 'default' : 'no-cache'),
            mode: 'cors',
            credentials: 'include',
            headers
        };

        return fetch(Fetcher.buildUrl(apiEndpoint, version, relativeUrl), fetchParams).then((response) => {
            return response.json();
        });
    }

    static PostReceiveJson(relativeUrl, version = 'v1', cache = false, json = false) {
        const headers = {};
        let body = null;

        if (json) {
            headers['Content-Type'] = 'application/json';
            body = JSON.stringify(json);
        }

        if ('apiKey' in Fetcher.globals) {
            headers['X-Api-Key'] = Fetcher.globals.apiKey
        }

        return fetch(Fetcher.buildUrl(apiEndpoint, version, relativeUrl), {
            body,
            headers,
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            cache: (cache ? 'no-cache' : 'default')
        }).then((response) => {
            return response.json();
        });
    }
}
