<template>
    <div class="mfw-review-completed-wrap">
        <div class="mfw-review-completed-img"><img :src="require('@/assets/images/feedback-popup.png')" alt=""></div>
        <div class="mfw-review-completed-title">{{ $t('form.completed.title') }}</div>
        <div class="mfw-review-completed-content">
            <div class="mfw-review-completed-text">{{ $t('form.completed.text') }}</div>
            <a :href="backURL" class="mfw-button mfw-button-fill mfw-review-completed-button"
               v-if="backURL">
                {{$t('feedback.Completed_Button') }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubmittedStepGeneral",
    props: ['backURL']
}
</script>

<style scoped>

</style>
