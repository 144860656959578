<template>
    <div class="mfw-review-step" :style="{height: height }"
         :class="[!isExpanded && isPassed ? 'mfw-filled-in' : '', isCollapsing ? 'mfw-collapsing' : '']">
        <Spinner v-if="isCollapsing" :size="50"/>
        <div class="mfw-step-number">2</div>
        <div class="mfw-step-title" ref="title">{{ $t('form.Employee') }}</div>
        <div v-show="isExpanded || isPassed" ref="content" class="mfw-step-content">
            <template v-if="loading">
                <!--Loading...-->
            </template>
            <template v-else>
                <QuestionList
                    v-on:Submit="next"
                    :isExpanded="isExpanded"
                    :isFilled="isPassed"
                    v-bind:offsetY="offsetY"
                    :categoriesMap="viewData.categoriesMap"
                    :questions="viewData.questions">

                    <div class="mfw-button-wrap" slot="bottom">
                        <a href="javascript:void(0);" class="mfw-button mfw-button-transparent"
                           v-on:click="back">{{ $t('form.Back') }}</a>
                        <a href="javascript:void(0);"
                           class="mfw-button mfw-button-fill mfw-button-next mfw-d-submit-questions">{{ $t('form.Next')
                            }}</a>
                    </div>
                </QuestionList>
            </template>
        </div>
    </div>
</template>

<script>
    import * as formComponents from './formComponents/';
    import QuestionList from './common/QuestionList';
    import Spinner from './common/Spinner'

    export default {
        components: Object.assign(formComponents, {QuestionList, Spinner}),
        name: "EmployeeResponse",
        props: ['loading', 'isExpanded', 'isFilled', 'viewData', 'offsetY'],
        data() {
            return {
                height: 'auto',
                maxHeight: null,
                isCollapsing: false,
                OPACITY_CONTENT_DURATION: 100,
                SPINNER_DURATION: 300,
                COLLAPSE_DURATION: 600,
                setTimeoutPromise: (ms) => new Promise(resolve => setTimeout(resolve, ms)),
                isPassed: false
            };
        },

        watch: {
            isExpanded: function (newValue) {
                let self = this;
                if (!newValue) return;

                self.isCollapsing = true;
                self.setTimeoutPromise(self.SPINNER_DURATION)
                    .then(() => {
                        self.isCollapsing = false;
                    })
            },

            isFilled: function (newValue) {
                let self = this;

                if (newValue) return;

                self.isCollapsing = true;
                self.isPassed = false;
                self.height = self.maxHeight + 'px';

                self.setTimeoutPromise(self.COLLAPSE_DURATION)
                    .then(() => {
                        self.isCollapsing = false;
                        self.height = 'auto';
                    })
            },
        },
        methods: {
            next() {
                let self = this;
                let parentPaddingTop = parseInt(window.getComputedStyle(this.$el, null).getPropertyValue('padding-top'));
                let parentPaddingBottom = parseInt(window.getComputedStyle(this.$el, null).getPropertyValue('padding-bottom'));

                self.isCollapsing = true;
                self.height = self.$refs.title.offsetHeight + self.$refs.content.clientHeight + parentPaddingTop + parentPaddingBottom + 'px';
                self.maxHeight = self.$el.offsetHeight;

                self.setTimeoutPromise(self.OPACITY_CONTENT_DURATION)
                    .then(() => {
                        self.isPassed = true;
                        self.$emit('changeView', 'next');
                        self.setTimeoutPromise(self.SPINNER_DURATION)
                            .then(() => {
                                self.isCollapsing = false;
                            });
                        self.setTimeoutPromise(0)
                            .then(() => {
                                self.height = self.$refs.title.offsetHeight + self.$refs.content.clientHeight + parentPaddingTop + parentPaddingBottom + 'px';
                                self.setTimeoutPromise(self.COLLAPSE_DURATION)
                                    .then(() => {
                                        self.height = 'auto';
                                    })
                            })
                    })

            },

            back() {
                let self = this;

                self.isCollapsing = true;
                self.height = this.$el.offsetHeight + 'px';
                self.$emit('changeView', 'back');

                self.setTimeoutPromise(self.COLLAPSE_DURATION)
                    .then(() => {
                        self.isCollapsing = false;
                        self.height = 'auto';
                    })
            }
        }
    }
</script>
