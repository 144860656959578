export default {
    header: {
        Average: "Moyenne",
        Total_feedbacks: "Témoignages"
    },
    bottom: {
        All_feedbacks: "Tous témoignages"
    },
    form: {
        Value_is_mandatory: "Value is mandatory",
        Employee: "Professionnel de santé",
        Company: "Cabinets",
        VideoConsultation: "MedicoVideo",
        Please_select: "Merci de sélectionner",
        Back: "Retourner",
        Next: "Continuer",
        Submit: "Envoyer",
        Add_feedback: "Évaluez maintenant",
        General_information: "Informations générales",
        Reason_for_your_visit: "Motif de votre visite",
        Please_leave_a_review_for: "Merci de laisser un témoignage pour",
        Authentication_required: "La qualité et l'équité sont très importantes pour Medicosearch. Par conséquent, nous ne pouvons publier votre témoignage que si vous vous inscrivez ou vous identifiez.",
        completed: {
            title: "Témoignage terminé!",
            text: "Merci de nous avoir laissé votre témoignage. Votre avis contribuera à une plus grande transparence dans le système de soins de santé et fournira de l'aide à la décision importante aux futurs patients dans leur recherche d'un médecin approprié."
        },
        Error_404: {
            Title: "Page non trouvée!",
            Description: "Désolé, nous n'avons pas trouvé la page que vous recherchiez."
        },
        Error_500: {
            Title: "Quelque chose a mal tourné",
            Text: "S'il vous plaît, essayez à nouveau ou contactez <a href=\"mailto:support@medicosearch.ch\">l'assistance Medicosearch</a>"
        }
    },
    feedback: {
        Surgery_Title: 'CABINET',
        Verified_Patient: "Patient vérifié",
        Last_Feedback: "Dernier classement",
        Rating_Description: "Ces évaluations reflètent les sentiments subjectifs des utilisateurs. Avec un petit nombre de notes, il est possible que la note globale ne soit pas représentative.",  //todo: translate
        Completed_Button: "Très bien!"
    }
}
