<template>
    <div class="mfw-widget-wrap">
        <Spinner v-if="isCollapsing" :size="50"/>

        <template v-if="!loading && !error && !alreadySubmitted && !notFoundError">
            <div class="mfw-review-header" slot="header">
                <div class="mfw-review-header-main-title">{{ $t('form.Please_leave_a_review_for') }}:</div>
                <div class="mfw-review-header-row">
                    <MedicoInfo :medicoInfo="medicoInfo"/>
                    <CompanyInfo :companyInfo="companyInfo"/>
                </div>
            </div>
        </template>

        <template v-if="!alreadySubmitted && !notFoundError">
            <div slot="body">
                <template v-if="loading && !error">
                    <!--Loading...-->
                </template>
                <template v-else-if="!error">
                    <div class="mfw-review-step-wrap">
                        <component
                            :ref="step.type"
                            :is="step.component"
                            v-bind="step.props"
                            v-for="step in steps"
                            :key="step.type"
                            v-on:changeView="step.onChangeView"
                        ></component>
                    </div>

                </template>
                <div v-else>
                    <div class="mfw-review-completed-wrap mfw-review-error-wrap">
                        <div class="mfw-review-completed-title">{{ $t('form.Error_500.Title') }}</div>
                        <div class="mfw-review-completed-content">
                            <div class="mfw-review-completed-text mfw-review-error-text" v-html="error"></div>
                            <a :href="backURL" class="mfw-button mfw-button-fill mfw-review-completed-button"
                               v-if="backURL">
                                {{$t('feedback.Completed_Button') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="alreadySubmitted">
            <component v-bind:is="submittedStepName" :backURL="backURL" />
        </template>
        <template v-if="notFoundError">
            <div class="mfw-review-completed-wrap mfw-review-error-wrap">
                <div class="mfw-review-completed-title">{{ $t('form.Error_404.Title') }}</div>
                <div class="mfw-review-completed-content">
                    <div class="mfw-review-completed-text">{{ $t('form.Error_404.Text') }}</div>
                    <a :href="backURL" class="mfw-button mfw-button-fill mfw-review-completed-button"
                       v-if="backURL">
                        {{$t('feedback.Completed_Button') }}
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Fetcher from './utils/Fetcher'
    import CommonMixin from './utils/mixins/Common'
    import {
        CompanyInfo,
        CompanyResponse,
        EmployeeResponse,
        GeneralInfo,
        MedicoInfo
    } from './components/LeaveFeedbackModal/*'
    import SurgeryLoader from './loaders/SurgeryLoader'
    import LinkGenerator from './utils/mixins/LinkGenerator'
    import Spinner from './components/LeaveFeedbackModal/common/Spinner'
    import {scrollToY} from './utils/Scroller'
    import VideoConsultationResponse from './components/LeaveFeedbackModal/VideoConsultationResponse'
    import SubmittedStepGeneral from "./components/LeaveFeedbackModal/completedStep/SubmittedStepGeneral";
    import SubmittedStepSwissSmile from "./components/LeaveFeedbackModal/completedStep/SubmittedStepSwissSmile";
    import SubmittedStepPhysiozentrum from "./components/LeaveFeedbackModal/completedStep/SubmittedStepPhysiozentrum";

    const STEP_COMPONENT = {
        MEDICOPROFILE: EmployeeResponse,
        SURGERYPROFILE: CompanyResponse,
        MEDICOVIDEO: VideoConsultationResponse
    }

    const QUESTIONNAIRE_TYPE = {
        MEDICOPROFILE: 'MEDICOPROFILE',
        SURGERYPROFILE: 'SURGERYPROFILE',
        MEDICOVIDEO: 'MEDICOVIDEO'
    }

    export default {
        name: 'FormFeedbackApp',
        props: ['token', 'backURL', 'offsetY', 'onSubmitSuccess', 'onShowAlreadySubmittedScreen'],
        components: {
            GeneralInfo, EmployeeResponse, CompanyResponse, VideoConsultationResponse, CompanyInfo, MedicoInfo, Spinner, SubmittedStepGeneral, SubmittedStepSwissSmile, SubmittedStepPhysiozentrum
        },
        mixins: [
            CommonMixin,
            LinkGenerator,
        ],
        created: async function () {
            await this.loadFeedbackDetails()
            this.load()
            if (['number', 'string'].indexOf(typeof this.offsetY) != -1) {
                this.offsetY = () => this.offsetY
            }
            window.addEventListener('resize', this.setResponsiveClasses)
            this.setResponsiveClasses()
        },

        mounted () {
            this.loadStyles()
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.setResponsiveClasses)
        },
        computed: {
            submittedStepName() {
                if (!this.feedbackDetails) {
                    return 'SubmittedStepGeneral'
                }
                switch(this.feedbackDetails.finalScreenType) {
                    case 'SWISS_SMILE':
                        return 'SubmittedStepSwissSmile'
                    case 'PHYSIOZENTRUM':
                        return 'SubmittedStepPhysiozentrum'
                    case 'GENERAL':
                    default: {
                        return 'SubmittedStepGeneral'
                    }
                }
            }
        },
        data () {
            return {
                alreadySubmitted: false,
                COLLAPSE_DURATION: 600,
                isCollapsing: false,
                loading: true,
                alreadyLeft: false,
                error: false,
                notFoundError: false,
                steps: [],
                companyInfo: null,
                medicoInfo: null
            }
        },
        watch: {
            alreadySubmitted: function (newValue) {
                if(newValue){
                    this.onShowAlreadySubmittedScreen()
                }
            }
        },
        methods: {
            isSubmitted () {
                this.isCollapsing = true
                scrollToY(this.offsetY(), this.COLLAPSE_DURATION, 'linear')
            },
            setResponsiveClasses: function () {
                let WIDTH_STEPS = [1201, 992, 768, 481],
                    widgetWidth = this.$el.clientWidth

                if (widgetWidth) {
                    WIDTH_STEPS.forEach(function (width) {
                        if (widgetWidth <= width) {
                            this.$el.classList.add('w' + width)
                        } else {
                            this.$el.classList.remove('w' + width)
                        }
                    }, this)
                } else {
                    setTimeout(this.setResponsiveClasses.bind(this), 100)
                }
            },

            loadStyles () {
                if (__ASSETS_ENDPOINT__) {
                    let style = document.createElement('link')
                    style.setAttribute('rel', 'stylesheet')
                    style.setAttribute('type', 'text/css')
                    style.setAttribute('href', __ASSETS_ENDPOINT__ + '/css/app.css')

                    this.$el.prepend(style)
                }
            },
            async load () {
                if (this.alreadySubmitted || this.notFoundError) {
                    return
                }

                if (!this.error) {

                    /** load GeneralInfo view's data */
                    await Promise.all([this.loadEmployeeInfo(), this.loadCompanyInfo()])

                    /** now, all data related to first screen is loaded, we can display modal content */
                    this.loading = false
                    this.$emit('open')
                }
            },
            generalInfoSubmit (direction, reason) {
                this.feedbackDetails.reason = reason

                this.changeView(direction)
            },

            changeView (data) {
                let ind = this.steps.findIndex(({ props }) => props.isExpanded)
                let direction, callback = null

                if (typeof data === 'object') {
                    direction = data.navigateTo
                    callback = data.done
                } else {
                    direction = data
                }

                if (direction === 'next') {
                    if (ind !== (this.steps.length - 1)) {
                        this.steps[ind].props.isFilled = true
                        this.changeCurrentStep(this.steps[ind + 1])

                    } else {
                        try {
                            // sync
                            this.submitAnswers()
                            this.steps[this.steps.length - 1].props.isExpanded = false
                            scrollToY(this.calculateFirstStepOffsetTop(), this.COLLAPSE_DURATION, 'linear')
                        } catch (e) {
                            if (callback) {
                                callback()
                            }
                            return
                        }
                    }
                } else {
                    if (ind !== 0) {
                        this.steps[ind - 1].props.isFilled = false
                        this.changeCurrentStep(this.steps[ind - 1])
                    }
                }

                if (callback) {
                    callback()
                }
            },
            calculateFirstStepOffsetTop () {
                return this.$refs[this.steps[0].type][0].$el.offsetTop + this.offsetY()
            },
            async loadFeedbackDetails () {
                let feedbackDataRequest = Fetcher.GetReceiveJson(`questionnaire/getFeedbackDataByToken/?token=${this.token}`, 'v2', false)
                await this.processResponse(feedbackDataRequest, async (data) => {
                    this.feedbackDetails = data.object

                    const steps = data.object.questionnaireTypes.map((stepName) => {
                        return {
                            type: stepName,
                            component: STEP_COMPONENT[stepName],
                            props: {
                                isExpanded: false,
                                isFilled: false,
                                offsetY: this.offsetY,
                                loading: true,
                                viewData: {
                                    questions: [],
                                    categoriesMap: {}
                                }
                            },
                            onChangeView: this.changeView
                        }
                    })

                    steps.unshift({
                        type: 'general',
                        component: GeneralInfo,
                        props: {
                            isExpanded: true,
                            isFilled: false,
                            offsetY: this.offsetY,
                            reason: data.object.reason
                        },
                        onChangeView: this.generalInfoSubmit
                    })

                    this.steps = steps
                    await Promise.all(data.object.questionnaireTypes.map((stepName) => this.loadViewData(stepName)))
                }, (error, data) => {
                    if (data.code === 'CONFLICT') {
                        this.alreadySubmitted = true
                    } else if (data.code === 'NOT_FOUND') {
                        this.notFoundError = true
                    }
                })
                return feedbackDataRequest
            },
            async submitAnswers () {
                let requestData = {
                    token: this.token,
                    reason: this.feedbackDetails.reason,
                    models: {
                        medico: {
                            profile: parseInt(this.feedbackDetails.medicoProfileId),
                            answers: {}
                        },
                        surgery: {
                            profile: parseInt(this.feedbackDetails.surgeryProfileId),
                            answers: {}
                        },
                        medicovideo: {
                            answers: {}
                        }
                    }
                }

                const QUESTIONNAIRE_TYPE_SUBMIT_REQUEST_PARAM_MAP = {
                    [QUESTIONNAIRE_TYPE.MEDICOPROFILE]: 'medico',
                    [QUESTIONNAIRE_TYPE.SURGERYPROFILE]: 'surgery',
                    [QUESTIONNAIRE_TYPE.MEDICOVIDEO]: 'medicovideo'
                }
                this.steps
                    .filter(({ type }) => Object.values(QUESTIONNAIRE_TYPE).includes(type))
                    .forEach((step) => {

                        for (let question of step.props.viewData.questions) {
                            requestData.models[QUESTIONNAIRE_TYPE_SUBMIT_REQUEST_PARAM_MAP[step.type]].answers[question.id] = question.value
                        }

                    })

                let submitAnswers = Fetcher.PostReceiveJson(`questionnaire/save`, 'v2', false, requestData)
                this.processResponse(submitAnswers, (data) => {
                    this.isCollapsing = false
                    this.alreadySubmitted = true
                })

                await submitAnswers
                this.onSubmitSuccess({
                    finalScreenType: this.feedbackDetails.finalScreenType
                })
            },
            async loadCompanyInfo () {
                let companyInfoLoading = SurgeryLoader.loadProfile(this.feedbackDetails.surgeryProfileId, { fields: ['name', 'poster152x152', 'addressId'] })

                let addressId, companyInfo = {}
                this.processResponse(companyInfoLoading, (data) => {
                    let item = data.object.items[0]

                    companyInfo.name = item.unitName
                    companyInfo.poster = item.poster152x152

                    addressId = item.addressId
                })

                await companyInfoLoading

                if (this.feedbackDetails.displayAddress) {
                    let addressLoading = Fetcher.GetReceiveJson(`addresses/get?addressIds=${addressId}&fields=zip,street,city`, 'v2', true)
                    this.processResponse(addressLoading, (data) => {
                        let item = data.object.items[0]

                        companyInfo.address = {
                            city: item.city,
                            street: item.street,
                            zip: item.zip
                        }
                    })

                    await addressLoading
                }
                this.$data.companyInfo = companyInfo

                return Promise.resolve()
            },
            async loadEmployeeInfo () {
                let loadEmployees = Fetcher.GetReceiveJson(`medicos/get?medicoIds=${this.feedbackDetails.medicoProfileId}&fields=fullName,poster152x152`, 'v2', true)
                this.processResponse(loadEmployees, (data) => {
                    this.$data.medicoInfo = data.object.items[0]
                })

                await loadEmployees
            },
            loadViewData (questionnaireType) {
                const step = this.steps.find(({ type }) => type === questionnaireType)
                const viewData = step.props.viewData

                return new Promise((resolve) => {
                    /** load questions */

                    let loadQuestions = Fetcher.GetReceiveJson(`questionnaire/questions/get?questionnaireType=${questionnaireType}&fields=text,
                        description,answerMetaId,categoryId,dependsOnQuestionId,dependsOnQuestionAnswerMetaOptionId,weight,mandatory`, 'v2', true)

                    this.processResponse(loadQuestions, (data) => {
                        viewData.questions = data.object.items

                        resolve(data.object.items)
                    })
                }).then((questions) => {
                    /** load categories */

                    let categoriesIds = [...new Set(questions.map(({ categoryId }) => categoryId))]

                    return new Promise((resolve, reject) => {
                        let loadCategories = Fetcher.GetReceiveJson(`questionnaire/questions/categories/get?categoryIds=${categoriesIds}&fields=name,showHeadline`, 'v2', true)

                        this.processResponse(loadCategories, (data) => {
                            let categories = data.object.items

                            categories.sort(categoryComparator)

                            let len = categories.length, categoriesMap = {}
                            for (let i = 0; i < len; i++) {
                                categories[i].questions = []
                                categoriesMap[categories[i].id] = categories[i]
                                categories[i].order = i
                            }

                            questions.sort(questionComparator)

                            questions.forEach((val) => {
                                categoriesMap[val.categoryId].questions.push(val)
                            })

                            viewData.categoriesMap = categoriesMap
                            resolve()
                        })
                    })
                }).then(() => {
                    let metaIds = [...new Set(viewData.questions.map(({ answerMetaId }) => answerMetaId))]

                    let loadAnswerMeta = Fetcher.GetReceiveJson(`questionnaire/questions/answerMetas/get?answerMetaIds=${metaIds}&fields=name,hasAnswerOptions`, 'v2', true)

                    return new Promise((resolve, reject) => {
                        this.processResponse(loadAnswerMeta, (data) => {
                            let metaMap = data.object.items.reduce((map, obj) => {
                                map[obj.id] = obj
                                return map
                            }, {})

                            viewData.questions.forEach((q) => {
                                q.meta = metaMap[q.answerMetaId]
                            })

                            resolve()
                        })
                    })
                }).then(() => {
                    let metaIds = viewData.questions.filter(el => {
                        return el.meta.hasAnswerOptions
                    }).map(({ answerMetaId }) => answerMetaId)

                    metaIds = [...new Set(metaIds)]

                    let loadAnswerMeta = Fetcher.GetReceiveJson(`questionnaire/questions/answerMetas/options/get?answerMetaIds=${metaIds}&fields=name,value,answerMetaId,rating,weight`, 'v2', true)

                    this.processResponse(loadAnswerMeta, (data) => {
                        let metaOptionsMap = {}

                        data.object.items.sort(answerOptionsComparator)

                        for (let item of data.object.items) {
                            if (!(item.answerMetaId in metaOptionsMap)) {
                                metaOptionsMap[item.answerMetaId] = []
                            }
                            metaOptionsMap[item.answerMetaId].push(item)
                        }

                        viewData.questions.forEach((q) => {
                            q.metaOptions = metaOptionsMap[q.answerMetaId]
                        })

                        step.props.loading = false
                    })
                })
            },
            changeCurrentStep (newStep) {
                const oldStep = this.steps.find((step) => step.props.isExpanded)
                oldStep.props.isExpanded = false
                newStep.props.isExpanded = true

                this.$nextTick(() => {
                    if (this.steps.indexOf(newStep) > this.steps.indexOf(oldStep)) {
                        // next
                        scrollToY(this.$refs[oldStep.type][0].$el.offsetTop + this.offsetY(), this.COLLAPSE_DURATION, 'linear')
                    } else {
                        scrollToY(this.$refs[newStep.type][0].$el.offsetTop + this.offsetY(), this.COLLAPSE_DURATION, 'linear')
                    }
                })
            }
        }
    }

    function categoryComparator (a, b) {
        if (a.showHeadline > b.showHeadline) {
            return -1
        }
        if (a.showHeadline < b.showHeadline) {
            return 1
        }

        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }

        return 0
    }

    function questionComparator (a, b) {
        if (a.weight > b.weight) {
            return -1
        }
        if (a.weight < b.weight) {
            return 1
        }

        return 0
    }

    function answerOptionsComparator (a, b) {
        if (a.rating < b.rating) {
            return -1
        }
        if (a.rating > b.rating) {
            return 1
        }

        return 0
    }
</script>
