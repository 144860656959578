// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import OneFeedbackApp from './OneFeedbackApp'
import FormFeedbackApp from './FormFeedbackApp.vue';
import VueI18n from 'vue-i18n'
import messages from '../locales/index'
import Fetcher from './utils/Fetcher'
import 'whatwg-fetch'
import './assets/css/style.scss'
import './assets/css/font-awesome.css'
import Config from "../config/variables";

import * as Sentry from "@sentry/vue";

Vue.use(VueI18n);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: "https://5ef98080e0a34de695bfb1b7e043a19a@sentry.service.msio.cloud/48",
        environment: process.env.NODE_ENV,
        integrations: [
            Sentry.browserTracingIntegration({
                tracePropagationTargets: [Config.domain]
            }),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.5 // [0.0-1.0] represents the percentage chance that any given transaction will be sent to Sentry.
    });
}

window.FeedbackWidget = class {
    constructor(config) {
        if (!('theme' in config)) {
            config.theme = 'default';
        }

        Fetcher.setLang(config.lang);
        if (config.apiKey) {
            Fetcher.setApiKey(config.apiKey);
        }

        this.vue = new Vue({
            i18n: new VueI18n({
                locale: config.lang,
                messages,
            }),
            el: config.container,
            components: {App},
            template: `<App ref="app" displayFeedbacksCount="${config.displayFeedbacksCount}" id="${config.id}" type="${config.type}"/>`
        });
    }
    resize() {
        this.vue.$refs.app.resize();
    }
};

window.OneFeedbackWidget = class {
    constructor(config) {
        if (!('theme' in config)) {
            config.theme = 'default';
        }

        Fetcher.setLang(config.lang);
        if (config.apiKey) {
            Fetcher.setApiKey(config.apiKey);
        }

        new Vue({
            i18n: new VueI18n({
                locale: config.lang,
                messages,
            }),
            el: config.container,
            components: {OneFeedbackApp},
            template: `<OneFeedbackApp id="${config.id}" type="${config.type}" />`
        });
    }
};

window.FormFeedbackWidget = class {
    constructor(config) {
        if (!('theme' in config)) {
            config.theme = 'default';
        }
        if (!('offsetY' in config)) {
            config.offsetY = 0;
        }
        if (!('onSubmitSuccess' in config)) {
            config.onSubmitSuccess = function(submitParams) {};
        }
        if (!('onShowAlreadySubmittedScreen' in config)) {
            config.onShowAlreadySubmittedScreen = function() {};
        }

        Fetcher.setLang(config.lang);
        if (config.apiKey) {
            Fetcher.setApiKey(config.apiKey);
        }
        new Vue({
            i18n: new VueI18n({
                locale: config.lang,
                messages,
            }),
            el: config.container,
            components: {FormFeedbackApp},
            template: `<FormFeedbackApp token="${config.token}" :offsetY="offsetY" :onSubmitSuccess="onSubmitSuccess" :onShowAlreadySubmittedScreen="onShowAlreadySubmittedScreen" backURL="${config.backURL}"/>`,
            methods: {
                offsetY: config.offsetY,
                onSubmitSuccess:  config.onSubmitSuccess,
                onShowAlreadySubmittedScreen: config.onShowAlreadySubmittedScreen
            }
        });
    }
};
