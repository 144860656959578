export default {
    header: {
        Average: "Media",
        Total_feedbacks: "Recensioni"
    },
    bottom: {
        All_feedbacks: "Tutto recensioni"
    },
    form: {
        Value_is_mandatory: "Value is mandatory", //todo: translate
        Employee: "Professionista sanitario",
        Company: "Studi medici",
        VideoConsultation: "MedicoVideo",
        Please_select: "Per favore seleziona",
        Back: "Torna",
        Next: "Avanti",
        Submit: "Invia",
        Add_feedback: "Vota adesso",
        General_information: "Informazioni generali",
        Reason_for_your_visit: "Motivo della tua visita",
        Please_leave_a_review_for: "Si prega di lasciare una recensione per",
        Authentication_required: "La qualità e l'equità sono molto importanti per Medicosearch. Pertanto, possiamo pubblicare la tua recensione solo se ti registri o accedi.",
        completed: {
            title: "Recensione completata!",
            text: "Grazie per aver lasciato la tua recensione. La vostra opinione contribuirà a una maggiore trasparenza nel sistema sanitario e fornirà ai futuri pazienti un'importante assistenza decisionale nella loro ricerca di un medico adatto."
        },
        Error_404: {
            Title: "Pagina non trovata!",
            Text: "Spiacenti, non abbiamo trovato la pagina che stavi cercando."
        },
        Error_500: {
            Title: "Qualcosa è andato storto.",
            Text: "Per favore, riprova più tardi o contatta <a href=\"mailto:support@medicosearch.ch\">l'assistenza di Medicosearch</a>"
        }
    },
    feedback: {
        Surgery_Title: 'STUDIO MEDICO',
        Verified_Patient: "Paziente verificato",
        Last_Feedback: "Ultima valutazione",
        Rating_Description: "Queste valutazioni riflettono i sentimenti soggettivi degli utenti. Con un numero limitato di valutazioni, è possibile che la valutazione complessiva non sia rappresentativa.",  //todo: verify translation!!! IMPORTANT
        Completed_Button: "Bene!"
    }
}
