import MedicoSurgeryRelationLoader from "../../loaders/MedicoSurgeryRelationLoader";
import SurgeryLoader from "../../loaders/SurgeryLoader";
import Fetcher from "../Fetcher";
require('../../polyfills/DOM.prepend.js');

export default {
  mounted() {
    this.loadStyles();
  },
  methods: {
    loadStyles() {
      if (__ASSETS_ENDPOINT__) {
        let style = document.createElement('link');
        style.setAttribute("rel", "stylesheet");
        style.setAttribute("type", "text/css");
        style.setAttribute("href", __ASSETS_ENDPOINT__ + "/css/app.css");

        this.$el.prepend(style);
      }
    },
    async initialLoad() {
      if (this.type === 'medico') {
        this.relationPromise = MedicoSurgeryRelationLoader.loadRelation(this.id, {fields: ['linkComponent']});
        this.processResponse(this.relationPromise, (data) => {
          this.relation = data.object.items[0];
          this.$data.allFeedbacksLink = this.base() + `/${this.relation.linkComponent}/medico/${this.relation.id}`;
          this.profileId = this.relation.medicoProfileId;
        });
        await this.relationPromise;
      } else {
        this.profileId = this.id;
      }

      Fetcher.globals.profileId = this.profileId;
      this.updateAllFeedbacksLink();
    },
    updateAllFeedbacksLink() {
      if (this.type === 'surgery') {
        this.processResponse(SurgeryLoader.loadProfile(this.profileId, {fields: ['linkComponent']}), (data) => {
          let profile = data.object.items[0];
          this.$data.allFeedbacksLink = this.base() + `/${profile.linkComponent}/surgery/${profile.id}`;
        });
      } else if (this.type === 'medico') {
        this.$data.allFeedbacksLink = this.base() + `/${this.relation.linkComponent}/medico/${this.relation.id}`;
      }
    }
  }
}