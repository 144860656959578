<template>
    <Rating v-on:input="$emit('input', $event)" :value="value" :question="question" :error="error" unselectedClass="unselected" />
</template>


<script>
    import Rating from './Rating'

    export default {
        props: ['value', 'question', 'error'],
        components: {
            Rating
        }
    }
</script>