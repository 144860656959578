<template>
    <div class="mfw-spinner"><i class="mfw-spinner-icon" v-bind:style="{fontSize: size + 'px'}"></i></div>
</template>
<style scoped>
    .mfw-spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.7);
        z-index: 100;
    }

    .mfw-spinner-icon {
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        border-radius: 100%;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
    }

    .mfw-spinner-icon:before {
        content: "\f055";
        color: #00D0C8;
        font: normal normal normal 26px/1 FontAwesome;
        font-size: inherit;
    }

    @-webkit-keyframes sk-scaleout {
        0% {
            -moz-transform: scale(0);
            -o-transform: scale(0);
            -ms-transform: scale(0);
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        100% {
            -moz-transform: scale(1.0);
            -o-transform: scale(1.0);
            -ms-transform: scale(1.0);
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
            opacity: 0;
        }
    }

    @keyframes sk-scaleout {
        0% {
            -moz-transform: scale(0);
            -o-transform: scale(0);
            -ms-transform: scale(0);
            -webkit-transform: scale(0);
            transform: scale(0)
        }
        100% {
            -moz-transform: scale(1.0);
            -o-transform: scale(1.0);
            -ms-transform: scale(1.0);
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
            opacity: 0;
        }
    }
</style>

<script>
    export default {
        name: "Spinner",
        props: ['size'],
    }
</script>

