<template>
    <div :class="(error ? 'mfw-has-error ' : '') + 'mfw-step-select-row'">
        <div class="mfw-step-select-txt">{{ question.text }}</div>
        <div class="mfw-rating-wrap">
            <div :class="'mfw-stars' + (value && !hover ?  ' selected' : '')" v-on:click="itemClick">
                <div class="mfw-rating-option">{{error ? $t('form.Please_select') : selectedOption }}</div>
                <div class="mfw-option-rating-wrap">
                    <a v-on:mouseover="mouseover($event)"
                       v-on:mouseout="mouseout"
                       :pkey="metaOption.id"
                       :key="metaOption.id"
                       v-for="metaOption  in question.metaOptions"
                       :class="value == metaOption.id ? 'active': (' ' + unselectedClass)" href="#">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:  {
            value: String,
            question: Object,
            error: Boolean,
            unselectedClass: {
                default: '',
                type: String
            }
        },

        data() {
            let data = {
                hover: false
            };

            return data;
        },
        watch: {
            error: function (errorValue) {
                if (!errorValue) {
                    this.$emit('update:error')
                }
            }
        },
        methods: {
            itemClick(event) {
                event.preventDefault();
                var answer = event.target.getAttribute('pkey');
                this.$emit('input', answer);
            },
            mouseover(event) {
                this.$el.classList.add('mfw-hover');
                this.hover = event.target.getAttribute('pkey');
            },
            mouseout() {
                this.$el.classList.remove('mfw-hover');
                this.hover = false;
            }
        },
        computed: {
            selectedOption() {
                if (!this.hover) {
                    let el = this.question.metaOptions.find((el) => el.id == this.value);
                    if (el) {
                        return el.name;
                    }
                } else {
                    let el = this.question.metaOptions.find((el) => el.id == this.hover);
                    if (el) {
                        return el.name;
                    }
                }

                return '';
            }
        }
    }
</script>
