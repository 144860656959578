<template>
    <div class="mfw-rating">
        <div class="mfw-rating-back">
            <span class="mfw-rating-back-item"></span>
            <span class="mfw-rating-back-item"></span>
            <span class="mfw-rating-back-item"></span>
            <span class="mfw-rating-back-item"></span>
            <span class="mfw-rating-back-item"></span>
        </div>
        <div class="mfw-rating-front" v-bind:style="ratingStarsStyle">
            <span class="mfw-rating-front-item"></span>
            <span class="mfw-rating-front-item"></span>
            <span class="mfw-rating-front-item"></span>
            <span class="mfw-rating-front-item"></span>
            <span class="mfw-rating-front-item"></span>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Rating',
    props: ['percentage'],
    data() {
      return {
        rating: parseFloat(this.percentage).toFixed(0) + '%'
      };
    },
    computed: {
        ratingStarsStyle() {
          return `width: ${this.rating}`;
        },
    }
  }
</script>
