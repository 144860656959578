<template>
  <div class="mfw-common-wrap">
    <div class="mfw-logo"></div>
    <div class="mfw-common">
      <AverageFeedbacksRating :average="average" :total="total"/>
      <!--<div class="mfw-common-txt">{{ $t('feedback.Rating_Description') }}</div>-->
    </div>
    <!--<div class="mfw-common">
        <a href="#" class="mfw-btn" v-on:click="$emit('openModal')">Jetzt bewerten</a>
    </div>-->
  </div>
</template>

<script>
  import AverageFeedbacksRating from "./AverageFeedbacksRating";

  export default  {
    name: "Header",
    props: ['average', 'total'],
    components: {
      AverageFeedbacksRating
    },
    data() {
      return {
        averageRounded: this.average.toFixed(0)
      };
    }
  }
</script>

