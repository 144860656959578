import Rating from './Rating';
import Input from './Input';
import Textarea from './Textarea';
import YesNo from './YesNo';
import Option from './Option';

export default {
    Rating,
    Input,
    Textarea,
    YesNo,
    Option
}
