<template>
  <div id="app">
    <div v-if="loading">
    </div>
    <div v-else>
      <template v-if="!error">
          <div :class="wrapperClassname">
            <div class="mfw-content">
              <div class="mfw-list-wrap">
                <Header v-on:openModal="openModal" v-bind:average="average" v-bind:total="totalFeedbacks" />
                <FeedbacksList ref="feedbacksList" v-bind:feedbacks="feedbacks" />
              </div>
              <div class="mfw-btn-wrap mfw-btn-with-logo">
                <a :href="allFeedbacksLink" class="mfw-link" id="popupFinal">{{ $t("bottom.All_feedbacks") }} ({{ totalFeedbacks }})</a>
              </div>
            </div>
          </div>
          <!--<LeaveFeedbackModal v-if="showModal" v-on:close="onClose" v-on:open="onOpen" v-on:reload="feedbackModalReload" :key="modalId" /> :key="modalId"-->
      </template>
      <span v-else>{{ error }}</span>
    </div>
  </div>
</template>

<style scoped>
  [v-cloak] {
    display: none;
  }
</style>


<script>
  import Header from './components/Header'
  import FeedbacksList from './components/FeedbacksList'
  // import LeaveFeedbackModal from './components/LeaveFeedbackModal'
  import Fetcher from './utils/Fetcher'
  import CommonMixin from './utils/mixins/Common'
  import LinkGenerator from "./utils/mixins/LinkGenerator";
  import AppInitial from "./utils/mixins/AppInitial";

  require('./polyfills/Array.find.js');

export default {
  name: 'App',
  props: ['displayFeedbacksCount', 'type', 'id'],
  components: {
    Header,
    FeedbacksList,
    // LeaveFeedbackModal
  },
  data: () => ({
    average: null,
    totalFeedbacks: null,
    error: false,
    loading: true,
    feedbacks: [],
    modalId: 1,
    showModal: false,
    allFeedbacksLink: '#',
    wrapperClassname: 'mfw-wrap'
  }),
  mixins: [
    CommonMixin,
    LinkGenerator,
    AppInitial
  ],
  async created() {
    await this.initialLoad();
    this.reload();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this._setWidthClass();
    }, true);
    this._setWidthClass();
  },
  methods: {
    onClose() {
      this.showModal = false;
      document.body.classList.remove('mfw-popup-open');
    },
    onOpen() {
      document.body.classList.add('mfw-popup-open');
    },
    openModal() {
      this.showModal = true;
    },
    feedbackModalReload() {
      this.reload();
      this.showModal = false;
      document.body.classList.remove('mfw-popup-open');
      // this.modalId += 1; // recreate modal component, modalId can be used to keep modal state after close
    },
    reload() {
      this.summary = Fetcher.GetReceiveJson(`feedbacks/${this.profileId}/summary`);

      this.feedbacks = Fetcher.GetReceiveJson(`feedbacks/${this.profileId}?limit=${this.displayFeedbacksCount}`);

      this.processResponse(this.feedbacks, (data) => {
          this.$data.feedbacks = data.object.items.map((item) => {
            return {
              dateCreated: item.dateCreated,
              feedbackTitle: item.feedbackTitle,
              feedbackDescription: item.feedbackDescription,
              roundedRating: item.roundedRating
            };
          });
      });
    },
    _setWidthClass() {
      const MIN_CONTAINER_WIDTH_FOR_WIDE_VIEW = 600;
      if (this.$el.clientWidth <= MIN_CONTAINER_WIDTH_FOR_WIDE_VIEW) {
        this.wrapperClassname = 'mfw-wrap mfw-wrap-xs';
      } else {
        this.wrapperClassname = 'mfw-wrap';
      }
    },
    resize() {
      this._setWidthClass();
      this.$refs.feedbacksList.resize();
    }
  },
  async beforeMount() {
    if (this.relationPromise) {
      await this.relationPromise;
    }
    await setImmediate(async () => {
      this.processResponse(this.summary, (data) => {
        this.$data.average = data.object.summary.average;
        this.$data.totalFeedbacks = data.object.summary.total;
        this.$data.loading = false;
      }, (error) => {
        this.$data.loading = false;
        this.$data.error = 'message' in error ? error.message : error;
      });
      await this.summary;
    })
  }
}
</script>
