export default {
  header: {
    Average: "Mittelwert",
    Total_feedbacks: "Bewertungen"
  },
  bottom: {
    All_feedbacks: "Alle Bewertungen"
  },
  form: {
    Value_is_mandatory: "Value is mandatory", //todo: translate
    Employee: "Leistungserbringer",
    Company: "Praxis",
    VideoConsultation: "MedicoVideo",
    Please_select: "Bitte wählen",
    Back: "Back",
    Next: "Weiter",
    Submit: "Senden",
    Add_feedback: "Jetzt bewerten",
    General_information: "Allgemeine Informationen",
    Reason_for_your_visit: "Ursache Ihres Besuches",
    Please_leave_a_review_for: "Bitte hinterlassen Sie eine Rezension für",
    Authentication_required: "Qualität und Fairness sind bei Medicosearch sehr wichtig. Daher können wir Ihre Bewertung nur veröffentlichen, wenn Sie sich jetzt registrieren oder einloggen.",
    completed: {
      title: "Bewertung abgeschlossen!",
      text: "Wir bedanken uns bei Ihnen herzlich für die Abgabe Ihrer Bewertung. Mit Ihrer Bewertung tragen Sie zu mehr Transparenz im Gesundheitswesen bei und bieten künftigen Patienten eine wichtige Entscheidungshilfe bei der Suche nach einem geeigneten Arzt."
    },
    Error_404: {
        Title: "Seite nicht gefunden!",
        Text: "Die Seite, nach der Sie suchen, können wir leider nicht finden."
    },
    Error_500: {
      Title: "Etwas ist schief gelaufen",
      Text: "Bitte versuchen Sie später erneut oder kontaktieren Sie <a href=\"mailto:support@medicosearch.ch\">Medicosearch Support</a>"
    }
  },
  feedback: {
    Surgery_Title: 'PRAXIS',
    Verified_Patient: "Verified Patient",
    Last_Feedback: "Letzte Bewertung",
    Rating_Description: "Diese Bewertungen spiegeln das subjektive Empfinden der Nutzer wider. Bei einer geringen Anzahl an Bewertungen ist es möglich, dass das Gesamturteil nicht repräsentativ ist.",
    Completed_Button: "Okay",//todo: translate
  }
}
